import React from 'react';
import { FC } from 'react';
import { Wrapper } from './Tag.elements';

export interface TagProps {
    name: string;
    color: string;
}

const TagTemplate: FC<TagProps> = ({ color, name }) => {
    return <Wrapper color={color}>{name}</Wrapper>;
};

export default TagTemplate;

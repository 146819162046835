import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.span<{ color: string }>`
    ${(prop) => `color: ${prop.color ? prop.color : prop.theme.colors.black}`};
    ${(prop) => `border: 1px solid ${prop.color ? prop.color : prop.theme.colors.black}`};
    padding: ${rem(5)} ${rem(10)};
    margin-right: ${rem(10)};
    margin-bottom: ${rem(5)};
    border-radius: ${rem(4)};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    display: inline-block;
`;

import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div``;

export const Materials = styled.div<{ isOpen?: boolean }>`
    padding: ${rem(15)};
    ${(prop) => (prop.isOpen ? `background: ${prop.theme.colors.darkLight}` : '')};
    margin-top: ${rem(50)};
`;

export const Header = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
`;

export const Label = styled.span`
    cursor: pointer;
    display: inline-block;
    margin-left: ${rem(30)};
`;

export const ArrowIcon = styled(IconTemplate)`
    position: absolute;
    left: ${rem(0)};
    cursor: pointer;

    &.isOpen {
        transform: rotate(180deg);
    }

    svg {
        margin-bottom: -${rem(4)};
        width: ${rem(15)};
        height: ${rem(16)};

        path {
            fill: ${({ theme }) => theme.colors.primary} !important;
        }
    }
`;

export const FileIcon = styled(IconTemplate)`
    margin-right: ${rem(25)};

    svg {
        width: ${rem(20)};
        height: ${rem(20)};
    }
`;

export const Material = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${rem(10)};
`;

export const Name = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
`;
